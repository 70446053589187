
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../services/service';

function AdventureApplication() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', applicantname: '', name: '', applicantaddress: '',
            whatsappno: '', activitylocationaddress: '', activityname: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [activityCategory, setActivityCategory] = useState('');
    const [adharFile, setAdharFile] = useState('');
    const [panFile, setPanFile] = useState('');

    const applicantTypeData = [{ label: "Person", value: "Person" }, { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Company", value: "Company" }, { label: "Partnership Business", value: "Partnership Business" },
    { label: "LLP (Limited Liability Partnership)", value: "LLP (Limited Liability Partnership)" },
    { label: "Trust", value: "Trust" }, { label: "Co-operative Society", value: "Co-operative Society" },
    { label: "Government", value: "Government" }];

    const activityCategoryData = [{ label: "Land Activities", value: "Land Activities" },
    { label: "Water Activities", value: "Water Activities" }, { label: "Air Activities", value: "Air Activities" }];

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();

    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        setLoading(true);
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
        setLoading(false);
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const maxSize = 5120; // in KB = 5MB        
        const errs = {};
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.applicantname) {
            isValid = false;
            errs[".applicantname"] = "Please enter Applicant Name";
        }
        if (!state.name) {
            isValid = false;
            errs[".name"] = "Please enter Name";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        // if (!state.applicantaddress) {
        //     isValid = false;
        //     errs[".applicantaddress"] = "Please enter";
        // }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }
        // if (!state.whatsappno) {
        //     isValid = false;
        //     errs[".whatsappno"] = "Please enter";
        // }
        if (!activityCategory) {
            isValid = false;
            errs[".activityCategory"] = "Please select";
        }
        if (!state.activityname) {
            isValid = false;
            errs[".activityname"] = "Please enter";
        }

        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload file";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload file";
        }

        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf'
            && getExtension(panFile.name).toLowerCase() !== 'jpg'
            && getExtension(panFile.name).toLowerCase() !== 'jpeg'
            && getExtension(panFile.name).toLowerCase() !== 'png'
            && getExtension(panFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf'
            && getExtension(adharFile.name).toLowerCase() !== 'jpg'
            && getExtension(adharFile.name).toLowerCase() !== 'jpeg'
            && getExtension(adharFile.name).toLowerCase() !== 'png'
            && getExtension(adharFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }

        if (panFile && panFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".panFile"] = "File size limit 5 MB";
        }
        if (adharFile && adharFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".adharFile"] = "File size limit 5 MB";
        }

        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }

    const activityCategoryChangeHandler = (ev) => {
        setActivityCategory(ev);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("AdventureApplicationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("ApplicantName", state.applicantname);
            formData.append("Name", state.name);
            formData.append("ApplicantType", applicantType.value);
            formData.append("ApplicantAddress", state.applicantaddress);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("WhatsAppNo", state.whatsappno);
            formData.append("ActivityCategory", activityCategory.value);
            formData.append("ActivityName", state.activityname);
            formData.append("ActivityLocationAddress", state.activitylocationaddress);
            formData.append("PANFile", panFile);
            formData.append("AadharFile", adharFile);
            fetch(
                service.ADD_ADVENTURE_APPLICATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/adventureapplicationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Adventure Application
                                </MDTypography>
                            </MDBox>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'red', paddingLeft: '30px' }}>
                                In case you do not find the required Sub Activity for Land/Air/Water Activity, kindly apply yourself through the `Adventure Application` link.
                                <br/>
                                जर Land/Air/Water Activity मधील अपेक्षित Sub Activity नोंदणी करतांना ड्रॉपडाउन मध्ये नसेल तर `Adventure Application` ह्या लिंक द्वारे नवीन नोंदणी करावी.
                            </div>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name of the Applicant"
                                                        fullWidth value={state.applicantname || ''}
                                                        name="applicantname"
                                                        onChange={changeHandler}
                                                        id="applicantname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Name of the Individual/Company/Partnership Firm/Trust etc. to be registered"
                                                        fullWidth value={state.name || ''}
                                                        name="name"
                                                        onChange={changeHandler}
                                                        id="name"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".name"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Applicant Address"
                                                        fullWidth value={state.applicantaddress || ''}
                                                        name="applicantaddress"
                                                        onChange={changeHandler}
                                                        id="applicantaddress"
                                                        required
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Whats App Number"
                                                        fullWidth value={state.whatsappno || ''}
                                                        name="whatsappno"
                                                        onChange={changeHandler}
                                                        id="whatsappno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".whatsappno"]}</div>
                                                </TableCell>

                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Adventure Activity Category
                                                    <Select
                                                        options={activityCategoryData}
                                                        name="activityCategory"
                                                        value={activityCategory}
                                                        onChange={activityCategoryChangeHandler}
                                                        placeholder="Select"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".activityCategory"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                 Activity Name
                                                    <MDInput label="Specify your activity"
                                                        fullWidth value={state.activityname || ''}
                                                        name="activityname"
                                                        onChange={changeHandler}
                                                        id="activityname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".activityname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Activity Location Address"
                                                        fullWidth value={state.activitylocationaddress || ''}
                                                        name="activitylocationaddress"
                                                        onChange={changeHandler}
                                                        id="activitylocationaddress"
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'green' }}>Applicant can add multiple addresses by numbering them as 1, 2, 3 ...</div>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".activitylocationaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>PAN Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Aadhar Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 200 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </div >
    );
}

export default AdventureApplication;